import React from "react"
import { Button, Text } from "@chakra-ui/react"
import { COLORS_PRIMARY } from "./vomux_colors"

interface VomuxButtonProps {
  children: React.ReactNode
  w?: string
  borderWidth?: string
  borderColor?: string
  backgroundColor?: string
  color?: string
  alignSelf?: string
  display?: string | object
  width?: string
  justifyContent?: string
  alignItems?: string
  onClick?: Function
}

export const VomuxButton: React.FC<VomuxButtonProps> = props => {
  return (
    <Button
      color={COLORS_PRIMARY.WHITE}
      backgroundColor={COLORS_PRIMARY.DARK_BLUE}
      {...props}
    >
      {props.children}
    </Button>
  )
}
