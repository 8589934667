import React from "react"
import { Text } from "@chakra-ui/react"
import { COLORS_PRIMARY } from "./vomux_colors"

interface VomuxTextProps {
  children: React.ReactNode
  m?: string
  p?: string
  paddingLeft?: string
  alignSelf?: string
  fontSize?: string | object
  color?: string
  backgroundColor?: string
  borderRadius?: string
  padding?: string
  fontWeight?: string
  marginLeft?: string | object
  marginRight?: string | object
  textDecoration?: string
  width?: string
  cursor?: string
  onClick?: Function
}

export const VomuxTextTitle: React.FC<VomuxTextProps> = props => {
  return (
    <Text
      fontFamily="quicksand"
      fontSize="3rem"
      fontWeight="bold"
      color={COLORS_PRIMARY.DARK_BLUE}
      {...props}
    >
      {props.children}
    </Text>
  )
}

export const VomuxTextSubTitle: React.FC<VomuxTextProps> = props => {
  return (
    <Text
      fontSize={{ base: "2.5rem", md: "3rem" }}
      color={COLORS_PRIMARY.BLACK}
      {...props}
    >
      {props.children}
    </Text>
  )
}

export const VomuxTextStandard: React.FC<VomuxTextProps> = props => {
  return (
    <Text fontSize="1.25rem" color={COLORS_PRIMARY.GRAY} {...props}>
      {props.children}
    </Text>
  )
}

export const VomuxTextAttention: React.FC<VomuxTextProps> = props => {
  return (
    <Text
      fontSize="2rem"
      color={COLORS_PRIMARY.LIGHT_BLUE}
      fontWeight="bold"
      {...props}
    >
      {props.children}
    </Text>
  )
}

export const VomuxTextSecondary: React.FC<VomuxTextProps> = props => {
  return (
    <Text
      fontSize="1.35rem"
      color={COLORS_PRIMARY.BLACK}
      fontWeight="bold"
      {...props}
    >
      {props.children}
    </Text>
  )
}

export const VomuxTextDifficulty: React.FC<VomuxTextProps> = props => {
  console.log("text", props.backgroundColor)
  return (
    <Text
      className="text"
      fontFamily="quicksand"
      fontSize="1.25rem"
      padding=".25rem"
      borderRadius="5px"
      backgroundColor={"green"}
      color={COLORS_PRIMARY.WHITE}
      {...props}
    >
      {props.children}
    </Text>
  )
}

export const VomuxTextLandingPage: React.FC<VomuxTextProps> = props => {
  return (
    <Text
      fontSize={{ sm: "3rem", base: "2.5rem" }}
      // color={"rgb(200,209,217)"}
      color={COLORS_PRIMARY.DARK_BLUE}
      fontFamily={"sans-serif"}
      fontWeight="700"
      lineHeight={1}
      letterSpacing={"-0.02em"}
      {...props}
    >
      {props.children}
    </Text>
  )
}

export const VomuxTextLandingPageSmall: React.FC<VomuxTextProps> = props => {
  return (
    <Text
      fontSize={{ md: "16px", sm: "12px", base: "10px" }}
      color={COLORS_PRIMARY.DARK_BLUE}
      fontFamily={"sans-serif"}
      fontWeight="400"
      lineHeight={1.333}
      letterSpacing={1}
      {...props}
    >
      {props.children}
    </Text>
  )
}
